.card_apresentacao {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 30rem;
  height: 7rem;
  padding: 0.3rem;
  padding-inline: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 1px rgba(71, 70, 70, 0.58);
  background-color: #f9fcfc;
}

.cpf_cnpj {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

.debitos {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.propostas {
  text-wrap: nowrap;
}

@media screen and (max-width: 600px) {
  .card_apresentacao {
    width: 100%;
  }
}
