.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.debitos {
  font-size: 2rem;
  margin-top: 1rem;
  font-weight: 600;
  color: #3d4141;
}

.skeleton_container {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  margin: 0 0 0 1rem;
}

@media screen and (max-width: 600px) {
  .skeleton_container {
    flex-direction: column;
  }

  .skeleton {
    margin: 1rem 0 0 0;
  }
}
