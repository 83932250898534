.container_inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttons {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.buttons_actions {
  text-align: center;
  padding-top: 20px;
}