.title {
  font-weight: 400;
  font-size: 1.5rem;
}

.sub_title {
  font-weight: 300;
}

.title_container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 0.7rem;
}
