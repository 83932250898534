.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-top: 1.5rem;
  flex-grow: 1;
}

.title {
  font-size: 2.2rem;
  font-family: "Popins", sans-serif;
  color: rgb(66, 70, 70);
}

.sub_title {
  text-align: center;
  font-weight: 400;
  font-style: italic;
  margin: 1rem 0 0 0;
}

.main {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.bottom {
  display: flex;
  flex-grow: 1;
  margin: 1.5rem 0 1.5rem 0;
}

.button_whatsapp {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 2rem;
  right: 2rem;
  background-color: #16c054;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
}

.button_whatsapp:hover {
  background-color: #14ad54;
  transform: scale(1.1);
}

.button {
  width: 15rem;
}

.form_container {
  width: 30%;
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(253, 253, 253);
  box-shadow: 0px 0px 15px 5px rgba(94, 92, 92, 0.58);
  border-radius: 0.5rem;
}

@media screen and (max-width: 600px) {
  .form_container {
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }

  .bottom {
    margin: 0;
  }
}

@media screen and (min-width: 1800px) {
  .form_container {
    width: 20%;
    height: 55%;
  }
}
