.proposta_card {
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: auto;
  box-shadow: 0px 0px 10px 0.1px rgba(71, 70, 70, 0.58);
  border-radius: 0.6rem 0.6rem 0.4rem 0.4rem;
  background-color: #f9fcfc;
}

.header {
  border-radius: 0.4rem 0.4rem 0 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}

.header_text {
  font-size: 1.4rem;
  color: #f0ece1;
  font-weight: 500;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #d8dfdf;
}

.button {
  padding: 0.6rem;
}

.button_auxilio {
  margin-bottom: 1rem;
}

.container_button_auxilio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.valor_entrada {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0.5rem 0;
}

.title {
  font-size: 1.2rem;
}

.valor {
  font-size: 1.5rem;
  font-weight: 500;
}

.data_vencimento {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  width: 100%;
  color: #100c25;
  margin-bottom: 1rem;
}

.vencimento {
  font-size: 1.2rem;
  font-weight: 500;
}

.parcelas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: 500;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  --padding-left: 1rem;
}

.container_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .proposta_card {
    width: 22rem;
    margin: 0.8rem 0rem 1rem 0;
  }

  .container_body {
    display: block;
  }

  .container {
    padding-left: 0;
  }
}
