.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 73rem;
  height: auto;
  display: flex;
  justify-content: space-between;
  opacity: 0.9;
}

.left_side {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_side {
  width: 50%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.imagem_container {
  width: 100%;
  height: 7rem;
  padding: 1rem;
  background-color: rgba(228, 248, 226, 0.678);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  border-radius: 1rem;
  opacity: 0.9;
}

.imagem {
  margin-right: 1rem;
}

.imagem_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.title {
  font-size: 2.2rem;
  font-family: "Popins", sans-serif;

  color: rgb(66, 70, 70);
}

.sub_title {
  text-align: center;
  font-weight: 400;
  font-style: italic;
  margin: 1rem 0 0 0;
  color: rgb(66, 70, 70);
}

.title_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  flex-grow: 1;
}

.right_side_main {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.right_side_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 1rem;
  width: 100%;
}

.button_whatsapp {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 2rem;
  right: 2rem;
  background-color: #16c054;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
}

.button_whatsapp:hover {
  background-color: #14ad54;
  transform: scale(1.1);
}

.button {
  width: 15rem;
}

.title_left_side {
  font-size: 1.5rem;
  text-align: center;
}

.title_left_side_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.imagem_login {
  margin: 0 1rem 0 1rem;
}

@media screen and (max-width: 600px) {
  .left_side {
    display: none;
  }
  .right_side {
    width: 100%;
  }

  .right_side_main {
    flex-grow: unset;
    margin: 2rem 0;
  }

  .card {
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(223, 183, 238, 0.21612394957983194) 0%,
      rgba(167, 234, 193, 0.26086408684567575) 100%
    );
  }
}

@media screen and (min-width: 1800px) {
  .card {
    width: 80rem;
    height: 40rem;
  }

  .imagem_container {
    height: 9rem;
  }

  .title_left_side {
    font-size: 2rem;
  }
  .title_left_side_container {
    margin-bottom: 1.5rem;
  }

  .title {
    font-size: 2.5rem;
    font-weight: 600;
  }

  .sub_title {
    font-size: 1.1rem;
  }
}
