.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;

  /* background-image: url("https://negocie-facto.cob.cloud/static/media/bg-fac.3db8a81b.png");
  background-size: cover; */
  /* background: linear-gradient(
    0deg,
    rgba(223, 183, 238, 0.21612394957983194) 0%,
    rgba(167, 234, 193, 0.26086408684567575) 100%
  ); */
  background: linear-gradient(
    0deg,
    rgba(223, 183, 238, 0.44013579552914917) 0%,
    rgba(167, 234, 193, 0.4765503613554797) 100%
  );
  /* background-image: url("./assets/bg-sisbracon.png");
  background-size: cover; */
}
