.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  box-shadow: 0px 0px 10px 2px rgba(71, 70, 70, 0.58);
  margin-bottom: 0.2rem;
}

.title {
  font-family: Mooli, sans-serif;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #f0ece1;
  text-align: center;
}

.voltar {
  font-size: 1rem;
  color: #f0ece1;
  left: 10px;
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 1.3rem;
  }
}
