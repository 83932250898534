.card_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25rem;
  height: 7rem;
  padding: 1rem;
  padding-inline: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 1px rgba(71, 70, 70, 0.58);
  background-color: #f9fcfc;
}

.razao_credor {
  font-size: 1.6rem;
  font-weight: 500;
}

.card_text {
  font-size: 1.5rem;
}

.n_processo {
  font-weight: 600;
}

.skeleton_container {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  margin: 0 0 0 1rem;
}
.proposta {
  font-size: 2rem;
  margin-top: 1rem;
  font-weight: 600;
  color: #eeeeee;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .card_logo {
    width: 22rem;
  }

  .skeleton_container {
    flex-direction: column;
  }

  .skeleton {
    margin: 1rem 0 0 0;
  }
}
