.footer {
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
  color: #f0ece1;
  box-shadow: 0px 0px 10px 2px rgba(71, 70, 70, 0.58);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
