.debitos_card {
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: auto;
  margin: 0.8rem;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0.1px rgba(71, 70, 70, 0.58);
  border-radius: 0.5rem;
  background-color: #f9fcfc;
  margin-bottom: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d8dfdf;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.nome_empresa {
  margin-left: 2rem;
  font-size: 1.5rem;
  font-family: Roboto, sans-serif;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 1rem;
}

.footer {
  --display: flex;
  align-items: center;
  justify-content: center;
  --height: 3rem;
}

.button {
  padding: 0.6rem;
  background-color: #6089e0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.3rem;
  font-family: Roboto, sans-serif;
}

.n_titulos {
  font-weight: 500;
}
.data_vencimento {
  font-weight: 500;
}

.titulos_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8dfdf;
  padding: 0.6rem;
}

@media screen and (max-width: 800px) {
  .titulos_container {
    flex-direction: column;
  }

  .paper {
    width: 30rem;
  }
}

.processo {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.paper {
  margin-top: 1rem;
  padding: 1rem 1.5rem 0 1rem;
  box-shadow: 0px 0px 5px 1px rgba(71, 70, 70, 0.58);
}
