.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: flex-end;
}

.card {
  width: 35%;
  height: 100%;
  background-color: rgba(239, 247, 241, 0.918);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 3px 0px 5px 0px rgba(124, 122, 122, 0.377);
}

.title_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  flex-grow: 1;
}

.title {
  font-size: 2.2rem;
  font-family: "Popins", sans-serif;
  color: rgb(66, 70, 70);
}

.sub_title {
  text-align: center;
  font-weight: 400;
  font-style: italic;
  margin: 1rem 0 0 0;
}

.main {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.bottom {
  display: flex;
  flex-grow: 1;
  margin-top: 1rem;
}

.bottom {
  display: flex;
  flex-grow: 1;
  margin: 1.5rem 0 1.5rem 0;
}

.button_whatsapp {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 2rem;
  left: 3rem;
  background-color: #16c054;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
}

.button_whatsapp:hover {
  background-color: #14ad54;
  transform: scale(1.1);
}

.button {
  width: 15rem;
}

@media screen and (min-width: 1800px) {
  .card {
    width: 30%;
  }
  .main {
    flex-grow: unset;
    margin: 3rem 0 1rem 0;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}
